document.querySelector('.navigation__dropdown-toggle').addEventListener('click', () => {
    document.querySelector('.navigation__dropdown').classList.toggle('navigation__dropdown--active');
});
document.addEventListener('click', event => {
    if (!event.target.closest('.navigation')) {
        document.querySelector('.navigation__dropdown').classList.remove('navigation__dropdown--active');
    }
});
document.addEventListener('keyup', event => {
    if ('Escape' === event.key) {
        document.querySelector('.navigation__dropdown').classList.remove('navigation__dropdown--active');
    }
});
